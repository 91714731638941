import { useEffect, useState } from 'react';
import { useDebounce } from 'usehooks-ts';
import { useSearchDefinitionsMutation } from '../../services/datum-api';

export interface SearchResultSuggestion {
  label: string;
  name: string;
  coverage: string;
}

interface UseDefinitionSearchProps {
  definitionInput: string;
}

export const useDefinitionSearch = ({ definitionInput }: UseDefinitionSearchProps) => {
  const [definitions, setDefinitions] = useState<SearchResultSuggestion[]>([]);
  const [searchDefinitions, { isLoading: isSearchingDefinitions }] = useSearchDefinitionsMutation();
  const debouncedDefinitionInput = useDebounce<string>(definitionInput, 400);

  useEffect(() => {
    async function doSearch() {
      if (debouncedDefinitionInput.length > 1) {
        const response = await searchDefinitions({ searchTerm: debouncedDefinitionInput });
        if ('data' in response && response.data.objects) {
          setDefinitions(
            response.data.objects
              .map(({ coverage_percentage, label, name }) => ({
                label: label || name,
                name,
                coverage_percentage,
                coverage: `${Math.round(coverage_percentage * 100) / 100}%`,
              }))
              .sort((a, b) => b.coverage_percentage - a.coverage_percentage)
          );
        }
      } else {
        setDefinitions([]);
      }
    }

    doSearch();
  }, [debouncedDefinitionInput, searchDefinitions]);

  return {
    definitions,
    isSearchingDefinitions,
  };
};
