import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { omit } from 'ramda';
import hash from 'object-hash';
import { RecursiveRow } from '../../types';

const omitTargets = omit(['targets']);

interface CountsState {
  items: any[];
  multiplier: number | null;
}

const initialState: CountsState = {
  items: [],
  multiplier: null,
};

export const countsSlice = createSlice({
  name: 'counts',
  initialState,
  reducers: {
    setMultiplier: (state, action: PayloadAction<number | null>) => {
      return {
        ...state,
        multiplier: action.payload,
      };
    },
    updateMultiplier: (state) => {
      const targets = state.items.map((item) => {
        const itemTotal = item.weighted_total ? item.weighted_total : item.total;
        return item.targets ? ((item.targets / itemTotal) * 100) / 100 : 1;
      });

      return {
        ...state,
        multiplier: +Math.max(...targets).toFixed(2),
      };
    },
    updateCounts: (state, action: PayloadAction<object[]>) => {
      return {
        ...state,
        items: action.payload.map((count) => ({ ...count, id: hash(omitTargets(count)) })),
      };
    },
    updateTargets: (state, action: PayloadAction<{ row: RecursiveRow; value: string }>) => {
      const updatedRowIndex = state.items.findIndex(
        (row: RecursiveRow) => row.id === action.payload.row.id
      );

      if (updatedRowIndex < 0) return state;

      const newItemsArray = [...state.items];

      newItemsArray[updatedRowIndex].targets = parseFloat(action.payload.value);
      state.items = newItemsArray;

      return state;
    },
  },
});

export const { updateCounts, updateMultiplier, updateTargets, setMultiplier } = countsSlice.actions;

export default countsSlice.reducer;
