import { Button, Stack } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { Definition } from '../../components/Definition/Definition.index';
import { DefinitionPicker } from '../../components/DefinitionPicker/DefinitionPicker.index';
import { CountsProgressBar } from '../../components/CountsProgressBar/CountsProgressBar.index';
import { CountsAndTargetsTable } from '../../components/CountsAndTargetsTable/CountsAndTargetsTable.index';
import { Weighting } from '../../components/Weighting/Weighting.index';
import { addDefinition } from '../../state/bucket/slice';
import { setMultiplier, updateCounts } from '../../state/counts/slice';
import { DatumDefinitionResponseOption, DatumDefinitionResponseRange } from '../../types';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { selectDefinitions, selectWeight } from '../../state/bucket/selectors';
import { useGetCountsMutation } from '../../services/featherstone-api';
import { ArrowBack } from '@mui/icons-material';
import { selectCounts } from '../../state/counts/selectors';

export const Bucket = () => {
  const dispatch = useDispatch();
  const definitions = useSelector(selectDefinitions);
  const weight = useSelector(selectWeight);

  const onAdd = (definitionId: string, items: DatumDefinitionResponseOption[]) => {
    if (items.length > 0) {
      // assume list
      dispatch(
        addDefinition({
          definitionId,
          type: 'list',
          options: items.map((item) => ({ ...item, checked: true })),
        })
      );
    } else {
      // assume range
      dispatch(
        addDefinition({
          definitionId,
          type: 'range',
          ranges: [],
        })
      );
    }
  };

  const [getCounts, { error, isLoading, reset }] = useGetCountsMutation();

  const getRangeValues = (range: DatumDefinitionResponseRange) => {
    let values = [];

    if (range.min) {
      values.push({ operator: 'gte', value: parseFloat(range.min) });
    }

    if (range.max) {
      values.push({ operator: 'lte', value: parseFloat(range.max) });
    }

    return values;
  };

  const handleReset = () => {
    dispatch(updateCounts([]));
    reset();
  };

  const handleCalculate = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    const _getCheckedOptions = (definition: any) => {
      const options: DatumDefinitionResponseOption[] = definition.options;
      return options?.filter(({ checked }) => checked);
    };

    const custom_variables = definitions.map((definition) => {
      if (definition.type === 'list') {
        return {
          name: `${definition.definitionId}`,
          buckets: _getCheckedOptions(definition).map((option: DatumDefinitionResponseOption) => ({
            name: `${option.text}`,
            type: definition.type,
            values: [option.code],
            variable: definition.definitionId,
          })),
        };
      } else if (definition.type === 'range') {
        return {
          name: `${definition.definitionId}`,
          buckets: definition.ranges?.map((range: DatumDefinitionResponseRange) => ({
            name: `range(${range.min}, ${range.max})`,
            type: definition.type,
            values: getRangeValues(range),
            variable: definition.definitionId,
          })),
        };
      }
    });

    const result = await getCounts({
      custom_variables,
      variables: [],
      weight,
    });

    if ('data' in result) {
      dispatch(updateCounts(result['data']));
      // reset multiplier after each fetch of counts data
      dispatch(setMultiplier(null));
    }
  };

  const isCalculateDisabled = () => {
    if (definitions.length === 0 || isLoading) return true;
    return false;
  };

  const counts = useSelector(selectCounts);

  return (
    <div>
      {counts.length === 0 && (
        <Stack spacing={3} direction="column">
          <DefinitionPicker key="picker" onAdd={onAdd} />
          <Stack spacing={2} direction="column">
            {definitions.map((definition) => {
              return <Definition key={definition.definitionId} {...definition} />;
            })}
          </Stack>
          {definitions.length > 0 && <Weighting />}
          {error && (
            <Alert severity="error">
              {'data' in error
                ? JSON.stringify(error?.data as string)
                : 'Unexpected error ocurred - please retry'}
            </Alert>
          )}
          <Button
            onClick={handleCalculate}
            startIcon={<AutoFixHighIcon />}
            variant="outlined"
            disabled={isCalculateDisabled()}>
            Calculate
          </Button>
          {isLoading && <CountsProgressBar />}
        </Stack>
      )}
      {counts.length > 0 && (
        <Stack direction="column" spacing={3}>
          <Button onClick={handleReset} startIcon={<ArrowBack />} variant="outlined">
            Back
          </Button>
          <CountsAndTargetsTable data={counts} definitions={definitions} weight={weight} />
        </Stack>
      )}
    </div>
  );
};
