import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

import { DatumDefinitionResponseOption } from '../../types';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { toggleOption } from '../../state/bucket/slice';
import { selectDefinitionOptions } from '../../state/bucket/selectors';

interface DefinitionResponseOptionsProps {
  definitionId: string;
  options?: DatumDefinitionResponseOption[];
}

export const DefinitionResponseOptions = ({
  definitionId,
  options,
}: DefinitionResponseOptionsProps) => {
  const dispatch = useDispatch();
  const definitionOptions = useSelector(selectDefinitionOptions(definitionId));
  const selectedDefinitionCodes = definitionOptions
    ?.filter(({ checked }) => checked)
    .map(({ code }) => code);

  const handleToggle = (option: DatumDefinitionResponseOption) => {
    dispatch(toggleOption({ definitionId, option }));
  };

  return (
    <List>
      {options?.map((option) => {
        const labelId = `checkbox-list-label-${option.code}`;

        return (
          <ListItem key={option.code} disablePadding>
            <DenseListButton onClick={() => handleToggle(option)} dense>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selectedDefinitionCodes?.includes(option.code)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`[${option.code}] ${option.text}`} />
            </DenseListButton>
          </ListItem>
        );
      })}
    </List>
  );
};

const DenseListButton = styled(ListItemButton)`
  height: 25px;
`;
