import { reduce } from 'ramda';
import { RootState } from '..';
import { RecursiveRow } from '../../types';

const selectCountsRow = (row: RecursiveRow) => (state: RootState) => {
  return state.counts.items.find((item) => row.id === item.id);
};

export const selectCounts = (state: RootState) => state.counts.items;
export const selectCountsMultiplier = (state: RootState) =>
  state.counts.multiplier && state.counts.multiplier < 1 ? 1 : state.counts.multiplier;
export const selectCountsRowTargets = (row: RecursiveRow) => (state: RootState) =>
  selectCountsRow(row)(state)?.targets || '';

export const selectTotalTargets = (state: RootState) => {
  const counts = selectCounts(state);

  return reduce(
    (total, n: any) => {
      return total + (n.targets || 0);
    },
    0,
    counts
  );
};

export const selectMostDeficientCell = (state: RootState) => {
  return state.counts.items
    .filter((item) => item.targets > 0)
    .reduce(
      ({ id, mostLacking }, item) => {
        const lacking = item.targets / (item.weighted_total || item.total);
        if (lacking > mostLacking) {
          return { id: item.id, mostLacking: lacking };
        }

        return { id, mostLacking };
      },
      { id: null, mostLacking: 0 }
    );
};

export const selectIsMostDeficientRow = (row: RecursiveRow) => (state: RootState) => {
  const target = selectCountsRow(row)(state)?.targets;

  if (!target) return false;

  console.log(selectMostDeficientCell(state));
};
