import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

interface CountsProgressBarProps {
  initialIncrementInterval?: number;
  maxProgress?: number;
}

export const CountsProgressBar = function ({
  initialIncrementInterval = 500,
  maxProgress = 95,
}: CountsProgressBarProps) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const increaseProgress = (afterMs: number) => {
      setTimeout(() => {
        setProgress((prevProgress: number) =>
          prevProgress >= maxProgress ? maxProgress : prevProgress + 5
        );

        increaseProgress(afterMs * 1.8);
      }, afterMs);
    };

    increaseProgress(initialIncrementInterval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            progress
          )}%`}</Typography>
        </Box>
      </Box>
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2" color="text.secondary">
          {progress >= maxProgress ? 'No worries, it is just slow...' : 'Calculating counts...'}
        </Typography>
      </Box>
    </Box>
  );
};
