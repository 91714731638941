import { DatumDefinitionResponseOption, DatumDefinitionResponseRange } from '../../types';
import { DefinitionResponseOptions } from '../../components/DefinitionResponseOptions/DefinitionResponseOptions.index';
import { DefinitionRanges } from '../../components/DefinitionRanges/DefinitionRanges.index';
import { IconButton, Paper, Stack, Typography } from '@mui/material';
import styled from '@emotion/styled';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from 'react-redux';
import { removeDefinition } from '../../state/bucket/slice';

interface DefinitionProps {
  definitionId: string;
  options?: DatumDefinitionResponseOption[];
  ranges?: DatumDefinitionResponseRange[];
}

export const Definition = ({ definitionId, options, ranges }: DefinitionProps) => {
  const dispatch = useDispatch();

  return (
    <Wrap>
      <Stack>
        <Header direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h5">{definitionId}</Typography>
          <IconButton onClick={() => dispatch(removeDefinition(definitionId))}>
            <DeleteIcon />
          </IconButton>
        </Header>
        {options && <DefinitionResponseOptions definitionId={definitionId} options={options} />}
        {ranges && <DefinitionRanges definitionId={definitionId} ranges={ranges} />}
      </Stack>
    </Wrap>
  );
};

const Wrap = styled(Paper)`
  padding-top: 15px;
`;

const Header = styled(Stack)`
  padding-left: 15px;
  padding-right: 15px;
`;
