import { Bucket } from './containers/Bucket/Bucket.index';
import styled from '@emotion/styled';
import { theme } from './theme/minimal';
import { ThemeProvider } from '@mui/material';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Wrap>
        <Bucket />
      </Wrap>
    </ThemeProvider>
  );
}

const Wrap = styled.div`
  margin: 100px;
`;

export default App;
