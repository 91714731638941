import { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CountsAndTargetsTableProps,
  CountsAndTargetsTableColumnProps,
  RecursiveTableRowProps,
  DatumDefinition,
} from '../../types';
import { setMultiplier, updateMultiplier, updateTargets } from '../../state/counts/slice';
import {
  selectCountsMultiplier,
  selectCountsRowTargets,
  selectMostDeficientCell,
  selectTotalTargets,
} from '../../state/counts/selectors';

import { styled } from '@mui/material/styles';
import { ArrowForward, FileDownload } from '@mui/icons-material';
import { Button, IconButton, Stack, Tooltip } from '@mui/material';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { assoc, filter, groupBy, keys, keysIn, omit, prop, reduce } from 'ramda';
import orderBy from 'lodash/orderBy';
import { utils, writeFileXLSX } from 'xlsx';

const StyledCell = styled(TableCell)(() => ({
  display: 'flex',
  alignSelf: 'stretch',
  width: 160,
  maxWidth: '100%',
  borderLeft: '1px solid #eee',
  borderBottom: '1px solid #eee',
  paddingTop: '4px',
  paddingBottom: '4px',
}));

const StyledHeadCell = styled(StyledCell)(() => ({
  display: 'inline-flex',
  alignSelf: 'center',
}));

const StyledFootCell = styled(StyledCell)(() => ({
  border: 0,
  alignSelf: 'center',
  paddingTop: '8px',
  paddingBottom: '8px',
}));

const roundToDecimal = (value: number): number => {
  return Math.floor(value * 10) / 10;
};

const RecursiveTableRow = function ({ row, columns }: RecursiveTableRowProps) {
  const dispatch = useDispatch();
  const multiplier = useSelector(selectCountsMultiplier);
  const rowTarget = useSelector(selectCountsRowTargets(row));
  const mostDeficientCell = useSelector(selectMostDeficientCell);
  const tableRowRef = useRef<HTMLTableRowElement>(null);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      // setTargetsInput(value);
      dispatch(
        updateTargets({
          row,
          value,
        })
      );
    },
    [row, dispatch]
  );

  const mostDeficientCellStyling = mostDeficientCell.id === row.id ? { color: 'red' } : {};

  return (
    <TableRow ref={tableRowRef} sx={{ display: 'flex', flexGrow: 1 }} id={row.id}>
      {row.value && <StyledCell>{row.value}</StyledCell>}
      {/* COUNTS Column */}
      {row.total && (
        <StyledCell sx={mostDeficientCellStyling}>{roundToDecimal(row.total)}</StyledCell>
      )}
      {/* WEIGHTED COUNTS Column */}
      {row.weighted_total && (
        <StyledCell sx={mostDeficientCellStyling}>{roundToDecimal(row.weighted_total)}</StyledCell>
      )}
      {/* TARGETS Column with Input */}
      {row.total && (
        <StyledCell>
          <Input
            sx={{ alignSelf: 'baseline' }}
            type="number"
            placeholder="Targets"
            value={rowTarget}
            onChange={onChange}
            onWheel={(e) => e.currentTarget.blur()}
          />
        </StyledCell>
      )}
      {row.total && !row.weighted_total && multiplier !== null && (
        <StyledCell>{row.total && roundToDecimal(row.total * multiplier)}</StyledCell>
      )}
      {row.weighted_total && multiplier !== null && (
        <StyledCell>
          {row.weighted_total && roundToDecimal(row.weighted_total * multiplier)}
        </StyledCell>
      )}
      {/* RECURSION when `collection` array exists */}
      {row.collection && (
        <StyledCell colSpan={columns.length + 1} sx={{ padding: 0, border: 0 }}>
          <Table sx={{ display: 'flex' }}>
            <TableBody sx={{ display: 'flex', flexDirection: 'column' }}>
              {row.collection.map((childRow: any) => (
                <RecursiveTableRow
                  key={childRow.value + childRow.variable + childRow.total}
                  row={childRow}
                  columns={columns}></RecursiveTableRow>
              ))}
            </TableBody>
          </Table>
        </StyledCell>
      )}
    </TableRow>
  );
};

export const CountsAndTargetsTable = function ({
  data = [],
  definitions = [],
  weight = '',
}: CountsAndTargetsTableProps) {
  // TODO
  // - Can we make the enter button perform the submit action?
  // - Fix general Table Cell and Table Header Cell alignments

  const dispatch = useDispatch();
  const multiplier = useSelector(selectCountsMultiplier);
  const totalTarget = useSelector(selectTotalTargets);
  const totalWeightCountsRef = useRef<HTMLTableCellElement>(null);
  const totalTargetRef = useRef<HTMLTableCellElement>(null);
  const finalCountsRef = useRef<HTMLTableCellElement>(null);

  const orderedColumns = definitions.map((definition: any) => definition.definitionId);

  const getColumns = (): CountsAndTargetsTableColumnProps[] => {
    function _getHeader(key: string) {
      switch (key) {
        case 'total':
          return { headerName: 'Counts', tooltip: 'Panel capacity without a weighting criteria' };
        case 'weighted_total':
          return {
            headerName: `${weight} Counts`,
            tooltip: 'Panel capacity estimated with a weighting criteria',
          };
        default:
          return { headerName: `PDL: ${key}`, tooltip: '' };
      }
    }

    return keysIn(data[0])
      .filter((key) => !['id', 'targets'].includes(key)) // reject some fields
      .map((key) => ({
        field: key,
        ..._getHeader(key),
      }));
  };

  const getTotalCounts = (data: object[]): number => {
    return reduce(
      (sum, n: any) => {
        return sum + n.total;
      },
      0,
      data
    );
  };

  const getTotalWeightedCounts = (data: object[]): number => {
    return reduce(
      (sum, n: any) => {
        return sum + n.weighted_total;
      },
      0,
      data
    );
  };

  const getFinalCounts = (data: object[]): number => {
    if (!multiplier) return 0;
    return reduce(
      (sum, n: any) => {
        const total = n.weighted_total ? n.weighted_total : n.total;
        return sum + total * multiplier;
      },
      0,
      data
    );
  };

  const getRowTarget = (id: string) => {
    const row = document.getElementById(id);
    if (!row) return false;
    const input = row.getElementsByTagName('input');

    return input && input.length > 0 ? parseFloat(input[0].value) : false;
  };

  const groupByValue = (collection: any, value: string) => {
    return groupBy((buckets: any) => {
      return buckets[value.toLowerCase()];
    }, collection);
  };

  function nestedGrouping(data: any, columns: any, columnIndex: number): object[] {
    const currentDefinition = columns[columnIndex];
    if (!currentDefinition) return data;

    const grouped = groupByValue(data, currentDefinition);
    const groupedValidKeys = keys(grouped).filter((value) => value && value.length > 0);

    const definitionOptions = definitions
      .find(({ definitionId }) => definitionId === currentDefinition)
      ?.options?.map(prop('text'));

    const definitionRanges = definitions
      .find(({ definitionId }) => definitionId === currentDefinition)
      ?.ranges?.map((range) => `range(${range.min}, ${range.max})`);

    return groupedValidKeys
      .map((optionValue) => {
        const collection = grouped[optionValue];
        return {
          collection: nestedGrouping(collection, columns, columnIndex + 1),
          value: optionValue,
          variable: currentDefinition,
          total: columnIndex + 1 > columns.length ? data.total : null,
        };
      })
      .sort((a, b) => {
        if (definitionRanges) {
          return definitionRanges.indexOf(a.value) - definitionRanges.indexOf(b.value);
        }
        if (!definitionOptions) return 0;
        return definitionOptions.indexOf(a.value) - definitionOptions.indexOf(b.value);
      });
  }

  const groupedData = nestedGrouping(data, orderedColumns, 0);

  const handleSubmit = (e: React.MouseEvent<HTMLElement>) => {
    dispatch(updateMultiplier());
  };

  const isSubmitDisabled = () => {
    return false;
  };

  const onMultiplierChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value);
    dispatch(setMultiplier(value));
  };

  const spreadSheetData = () => {
    // Ordered By PDL Columns
    let sorted = orderBy(
      data,
      orderedColumns,
      orderedColumns.map((c) => 'asc')
    );

    // Append data for Final count with Multiplier column
    sorted = sorted.map((s: any) => {
      const { id, targets, total, weighted_total } = s;

      if (weighted_total) {
        s = assoc('weighted_total', roundToDecimal(weighted_total), s);
      }

      // Initial state of this value may be empty,
      // if that is the case, grab it manually here
      if (!targets) {
        s = assoc('targets', getRowTarget(id) ? getRowTarget(id) : 0, s);
      }

      s = assoc(
        'final',
        roundToDecimal(
          multiplier
            ? total && !weighted_total
              ? total
              : weighted_total * multiplier
            : total && !weighted_total
            ? total
            : weighted_total
        ),
        s
      );

      return omit(['id'], s);
    });

    return sorted;
  };

  const onDownload = useCallback(() => {
    function _getColumnWidth(index: number) {
      // Returns longer of the two character lengths (header name or cell value)
      const column = getColumns()[index];
      const definition = filter(
        (d) => d.definitionId?.toLowerCase() === column.field?.toLowerCase(),
        definitions
      );

      if (definition.length > 0) {
        // PDL-based column
        if (definition[0]?.options && definition[0]?.options.length > 0) {
          // options
          return definition[0]?.options?.reduce(
            (d, r) => Math.max(d, r.text.length),
            column.headerName.length
          );
        } else if (definition[0]?.ranges && definition[0]?.ranges.length > 0) {
          // ranges
          return definition[0]?.ranges?.reduce(
            (d, r) => Math.max(d, `range(${r.min}, ${r.max})`.length),
            column.headerName.length
          );
        } else {
          // default (length of column header text)
          return column.headerName.length;
        }
      } else {
        switch (column.field) {
          case 'total':
            return Math.max(getTotalCounts(data).toString().length, column.headerName.length) + 2;
          case 'weighted_total':
            return (
              Math.max(
                roundToDecimal(getTotalWeightedCounts(data)).toString().length,
                column.headerName.length
              ) + 2
            );
        }
      }
    }

    function _generateSheetJSColumns() {
      // Initial columns, PDLs and counts
      const columns = getColumns().map((col, idx) => {
        return {
          wch: _getColumnWidth(idx),
          v: col.headerName,
          t: 's',
        };
      });

      // Append additional columns with character length widths
      columns.push({
        wch: Math.max(totalTarget.toString().length, 7) + 2,
        v: 'Target',
        t: 's',
      });
      columns.push({
        wch: Math.max(roundToDecimal(getFinalCounts(data)).toString().length, 5) + 4,
        v: 'Final',
        t: 's',
      });

      return columns;
    }

    function _generateSheetJSFooter() {
      // First, get all the PDL columns with empty cell values
      let row = orderedColumns.map((c, i) => ({ v: '', t: 's' }));

      // Replace the last column of PDLs with the text "Total"
      row[row.length - 1] = {
        v: 'Total',
        t: 's',
      };

      // Append Counts total
      row.push({
        v: getTotalCounts(data) >= 0 ? getTotalCounts(data).toString() : '0',
        t: 'n',
      });

      // Append Weighted Counts total
      if (getTotalWeightedCounts(data) >= 0 || totalWeightCountsRef.current) {
        row.push({
          v:
            getTotalWeightedCounts(data) >= 0
              ? roundToDecimal(getTotalWeightedCounts(data)).toString()
              : totalWeightCountsRef.current
              ? roundToDecimal(parseFloat(totalWeightCountsRef.current.innerText)).toString()
              : '0',
          t: 'n',
        });
      }

      // Append Targets total
      row.push({
        v: totalTarget
          ? totalTarget.toString()
          : totalTargetRef.current
          ? totalTargetRef.current.innerText
          : '0',
        t: 'n',
      });

      // Append Final Count with Multiplier total
      row.push({
        v:
          getFinalCounts(data) > 0
            ? roundToDecimal(getFinalCounts(data)).toString()
            : finalCountsRef.current
            ? roundToDecimal(parseFloat(finalCountsRef.current.innerText)).toString()
            : '0',
        t: 'n',
      });

      return row;
    }

    // START SHEETJS INTEGRATION

    // Create Worksheet
    const ws = utils.json_to_sheet(spreadSheetData());
    ws['!cols'] = _generateSheetJSColumns();

    // Updates columns headers in sheet
    utils.sheet_add_json(ws, [ws['!cols']], { skipHeader: true });

    // Updates "Totals" row at the bottom
    utils.sheet_add_json(ws, [_generateSheetJSFooter()], { origin: -1, skipHeader: true }); // append to the end

    // Create Workbook
    const wb = utils.book_new();

    // Add existing Worksheet to Workbook
    utils.book_append_sheet(wb, ws, 'Feasibility');

    // Write file
    writeFileXLSX(wb, `Feasibility_Export_${new Date().toISOString().split('T')[0]}.xlsx`);
  }, [totalWeightCountsRef, totalTargetRef, finalCountsRef]);

  return (
    <Container maxWidth="xl" disableGutters={true} sx={{ px: 0 }}>
      <Tooltip title="Export to Excel">
        <span style={{ float: 'right' }}>
          <IconButton
            onClick={onDownload}
            color="primary"
            disabled={
              !groupedData ||
              groupedData.length <= 0 ||
              !multiplier ||
              !getFinalCounts(data) ||
              getFinalCounts(data) <= 0
            }
            sx={{ mb: 1 }}>
            <FileDownload />
          </IconButton>
        </span>
      </Tooltip>
      <Stack direction="column" spacing={3} sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow sx={{ display: 'flex', backgroundColor: '#F9FAFC' }}>
                  {getColumns().map((column: any) => (
                    <Tooltip
                      title={column.tooltip}
                      key={column.field + column.tooltip + column.headerName}>
                      <StyledHeadCell key={column.field}>{column.headerName}</StyledHeadCell>
                    </Tooltip>
                  ))}
                  <Tooltip title="Panel capacity required">
                    <StyledHeadCell>Targets</StyledHeadCell>
                  </Tooltip>
                  {multiplier !== null && (
                    <Tooltip title="Panel capacity estimated considering the multiplier. Calculated by multiplying Counts or Weighted Counts by the multiplier">
                      <StyledHeadCell>Final Count with Multiplier</StyledHeadCell>
                    </Tooltip>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {groupedData.map((row: any) => (
                  <RecursiveTableRow
                    key={row.value + row.variable + row.total}
                    row={row}
                    columns={getColumns()}></RecursiveTableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow sx={{ display: 'flex' }}>
                  {definitions.map(
                    (definition: any, index: number) =>
                      index < definitions.length - 1 && (
                        <StyledCell key={definition.definitionId} sx={{ border: 0 }}></StyledCell>
                      )
                  )}
                  <StyledFootCell>Total</StyledFootCell>
                  <StyledFootCell>{getTotalCounts(data)}</StyledFootCell>

                  {getTotalWeightedCounts(data) >= 0 && (
                    <StyledFootCell ref={totalWeightCountsRef}>
                      {roundToDecimal(getTotalWeightedCounts(data))}
                    </StyledFootCell>
                  )}

                  <StyledFootCell ref={totalTargetRef}>{totalTarget}</StyledFootCell>
                  <StyledFootCell ref={finalCountsRef}>
                    {getFinalCounts(data) > 0 && roundToDecimal(getFinalCounts(data))}
                  </StyledFootCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Paper>
        {multiplier !== null && (
          <FormControl variant="filled" sx={{ m: 1, minWidth: 200 }}>
            <Tooltip title="How much the existing panel needs to be expanded, or multiplied by, to meet the target panel capacity">
              <InputLabel>Multiplier</InputLabel>
            </Tooltip>
            <Input type="number" value={multiplier} onChange={onMultiplierChange} />
          </FormControl>
        )}
        <Button
          onClick={handleSubmit}
          startIcon={<ArrowForward />}
          variant="outlined"
          disabled={isSubmitDisabled()}>
          Submit
        </Button>
      </Stack>
    </Container>
  );
};
