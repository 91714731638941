import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import { DatumDefinitionResponseRange } from '../../types';
import { useDispatch, useSelector } from 'react-redux';
import { addRange, removeRange, updateRangeMax, updateRangeMin } from '../../state/bucket/slice';
import { selectDefinitionRanges } from '../../state/bucket/selectors';
import { nanoid } from 'nanoid';

interface DefinitionRangesProps {
  definitionId: string;
  ranges?: DatumDefinitionResponseRange[];
}

export const DefinitionRanges = ({ definitionId, ranges }: DefinitionRangesProps) => {
  const dispatch = useDispatch();
  const definitionRanges = useSelector(selectDefinitionRanges(definitionId));

  const handleAddRange = () => {
    dispatch(addRange({ definitionId, range: { min: '', max: '', id: nanoid() } }));
  };

  const handleRemoveRange = (index: number) => {
    dispatch(removeRange({ definitionId, index }));
  };

  const handleInputValidation = (value: any) => {
    try {
      if (value !== '' && isNaN(parseFloat(value))) {
        return { error: `Input must be an integer or decimal`, valid: false };
      }
      return { error: false, valid: true };
    } catch (err: any) {
      return { error: typeof err === 'object' ? err.message : err, valid: false };
    }
  };

  const handleUpdateRangeMin = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { value } = event.target;
    dispatch(updateRangeMin({ definitionId, index, min: value }));
  };

  const handleUpdateRangeMax = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { value } = event.target;
    dispatch(updateRangeMax({ definitionId, index, max: value }));
  };

  return (
    <List>
      <Button onClick={handleAddRange} variant="outlined">
        Add Range
      </Button>

      {ranges?.map((range, index) => {
        return (
          <Stack key={range.id} direction="row" spacing={1} alignItems="center" my={2}>
            <TextField
              placeholder="MIN"
              onChange={(e) => handleUpdateRangeMin(e, index)}
              value={definitionRanges?.find((r) => r.id === range.id)?.min}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              helperText={
                !handleInputValidation(definitionRanges?.find((r) => r.id === range.id)?.min).valid
                  ? handleInputValidation(definitionRanges?.find((r) => r.id === range.id)?.min)
                      .error
                  : 'Note: integers and decimals'
              }
              error={
                !handleInputValidation(definitionRanges?.find((r) => r.id === range.id)?.min).valid
              }
            />
            <Typography>≤ x ≤</Typography>
            <TextField
              placeholder="MAX"
              onChange={(e) => handleUpdateRangeMax(e, index)}
              value={definitionRanges?.find((r) => r.id === range.id)?.max}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              helperText={
                !handleInputValidation(definitionRanges?.find((r) => r.id === range.id)?.max).valid
                  ? handleInputValidation(definitionRanges?.find((r) => r.id === range.id)?.max)
                      .error
                  : 'Note: integers and decimals'
              }
              error={
                !handleInputValidation(definitionRanges?.find((r) => r.id === range.id)?.max).valid
              }
            />
            <IconButton onClick={() => handleRemoveRange(index)}>
              <DeleteIcon />
            </IconButton>
          </Stack>
        );
      })}
    </List>
  );
};
