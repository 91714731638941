import { configureStore } from '@reduxjs/toolkit';
import { datumApi } from '../services/datum-api';
import { featherstoneApi } from '../services/featherstone-api';
import bucketSlice from './bucket/slice';
import countsSlice from './counts/slice';

export const store = configureStore({
  reducer: {
    bucket: bucketSlice,
    counts: countsSlice,
    [datumApi.reducerPath]: datumApi.reducer,
    [featherstoneApi.reducerPath]: featherstoneApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(datumApi.middleware, featherstoneApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
