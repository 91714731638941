import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DatumDefinitionResponseOption, DatumDefinitionResponseRange } from '../../types';

interface CustomDefinition {
  type: 'list' | 'range';
  definitionId: string;
  options?: DatumDefinitionResponseOption[];
  ranges?: DatumDefinitionResponseRange[];
}

interface BucketState {
  definitions: CustomDefinition[];
  weight: string;
}

const initialState: BucketState = {
  definitions: [],
  weight: '',
};

export const bucketSlice = createSlice({
  name: 'bucket',
  initialState,
  reducers: {
    addDefinition: (state, action: PayloadAction<CustomDefinition>) => {
      if (
        state.definitions.find(
          (definition) => definition.definitionId === action.payload.definitionId
        )
      ) {
        return state;
      }

      return {
        ...state,
        definitions: [...state.definitions, action.payload],
      };
    },
    addRange: (
      state,
      action: PayloadAction<{ definitionId: string; range: DatumDefinitionResponseRange }>
    ) => {
      const { definitionId, range } = action.payload;
      const definition = state.definitions.find(
        (definition) => definition.definitionId === definitionId
      );

      if (!definition) return state;

      definition?.ranges?.push(range);
    },
    removeDefinition: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        definitions: state.definitions.filter(
          (definition) => definition.definitionId !== action.payload
        ),
      };
    },
    removeRange: (state, action: PayloadAction<{ definitionId: string; index: number }>) => {
      const { definitionId, index } = action.payload;
      const definition = state.definitions.find(
        (definition) => definition.definitionId === definitionId
      );

      if (!definition) return state;

      // Remove the range object at given index
      definition?.ranges?.splice(index, 1);
    },
    toggleOption: (
      state,
      action: PayloadAction<{ definitionId: string; option: DatumDefinitionResponseOption }>
    ) => {
      const { definitionId, option } = action.payload;
      const definition = state.definitions.find(
        (definition) => definition.definitionId === definitionId
      );

      if (!definition) return state;

      const definitionOption = definition?.options?.find(
        (defOption) => defOption.code === option.code
      );

      if (definitionOption) {
        definitionOption.checked = !definitionOption.checked;
      }
    },
    updateRangeMax: (
      state,
      action: PayloadAction<{ definitionId: string; index: number; max: string }>
    ) => {
      const { definitionId, index, max } = action.payload;
      const definition = state.definitions.find(
        (definition) => definition.definitionId === definitionId
      );

      if (!definition) return state;

      const definitionRange = definition?.ranges?.at(index);

      if (definitionRange) {
        definitionRange.max = max;
      }
    },
    updateRangeMin: (
      state,
      action: PayloadAction<{ definitionId: string; index: number; min: string }>
    ) => {
      const { definitionId, index, min } = action.payload;
      const definition = state.definitions.find(
        (definition) => definition.definitionId === definitionId
      );

      if (!definition) return state;

      const definitionRange = definition?.ranges?.at(index);

      if (definitionRange) {
        definitionRange.min = min;
      }
    },
    updateWeight: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        weight: action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addDefinition,
  addRange,
  toggleOption,
  removeDefinition,
  removeRange,
  updateRangeMax,
  updateRangeMin,
  updateWeight,
} = bucketSlice.actions;

export default bucketSlice.reducer;
