import { useDispatch, useSelector } from 'react-redux';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { updateWeight } from '../../state/bucket/slice';
import { selectWeight } from '../../state/bucket/selectors';

export const Weighting = () => {
  const dispatch = useDispatch();
  const weight = useSelector(selectWeight);

  const handleChange = (event: SelectChangeEvent) => {
    dispatch(updateWeight(event.target.value));
  };

  return (
    <FormControl variant="filled" sx={{ m: 1, minWidth: 200 }}>
      <InputLabel>Weighting Criteria</InputLabel>
      <Select value={weight} onChange={handleChange}>
        <MenuItem value="">
          <em>none</em>
        </MenuItem>
        <MenuItem value="pcomp">pcomp</MenuItem>
        <MenuItem value="pstart">pstart</MenuItem>
        <MenuItem value="inv_start_ratio">inv_start_ratio</MenuItem>
        <MenuItem value="start_complete_ratio">start_complete_ratio</MenuItem>
      </Select>
    </FormControl>
  );
};
