import {
  Alert,
  Autocomplete,
  Chip,
  LinearProgress,
  ListItem,
  Stack,
  TextField,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useGetDefinitionResponseOptionsMutation } from '../../services/datum-api';
import { useEffect, useState } from 'react';
import { DatumDefinitionResponseOption } from '../../types';
import { SearchResultSuggestion, useDefinitionSearch } from './useDefinitionSearch';

// Panel:1 is the only one used in MVP
const PANEL_ID = 'panel:1';

interface DefinitionPickerProps {
  onAdd: (definitionName: string, items: DatumDefinitionResponseOption[]) => void;
}

export const DefinitionPicker = ({ onAdd }: DefinitionPickerProps) => {
  const [definitionInput, setDefinitionInput] = useState('');
  const [getDefinition, { error, isLoading }] = useGetDefinitionResponseOptionsMutation();
  const [selectedDefinition, setSelectedDefinition] = useState<SearchResultSuggestion | null>(null);

  function isErrorShown(error: any) {
    // Suppress error message for 404s for now
    // (assumes `open-int`, `open-intranges` will respond this way)
    return 'originalStatus' in error && error?.originalStatus === 404 ? false : true;
  }

  useEffect(() => {
    async function onSubmit() {
      if (selectedDefinition) {
        const payload = await getDefinition({
          panel: PANEL_ID,
          definitionId: selectedDefinition.name,
        });

        if ('data' in payload && payload.data.items) {
          onAdd(selectedDefinition.name, payload.data.items);
          setSelectedDefinition(null);
          setDefinitionInput('');
        }

        // TODO :: better handling of `open-int` / `open-intrange` type questions
        // we'll just assume for now a 404 is an `open-int`
        if ('error' in payload) {
          onAdd(selectedDefinition.name, []);
          setSelectedDefinition(null);
          setDefinitionInput('');
        }
      }
    }
    onSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDefinition]);

  const { definitions, isSearchingDefinitions } = useDefinitionSearch({ definitionInput });

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Stack spacing={2}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Autocomplete
            sx={{ flex: 1 }}
            options={definitionInput.length > 0 ? definitions : []}
            value={selectedDefinition}
            onChange={(e, value: SearchResultSuggestion | null) => setSelectedDefinition(value)}
            loading={isSearchingDefinitions}
            getOptionLabel={(option) => option.name}
            renderOption={(props, { name, coverage, label }) => (
              <ListItem {...props}>
                <Stack
                  sx={{ flex: 1 }}
                  alignItems="center"
                  direction="row"
                  justifyContent="space-between">
                  <Stack direction="column">
                    <strong>{name}</strong>
                    <span>{label}</span>
                  </Stack>
                  <Chip variant="outlined" label={coverage} />
                </Stack>
              </ListItem>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ flex: 1 }}
                id="definition-name"
                label="Datum Definition Name"
                placeholder="Search for Datum definitions by Name or Label"
                variant="filled"
                value={definitionInput}
                onChange={(e) => setDefinitionInput(e.target.value)}
              />
            )}
          />

          {error && isErrorShown(error) && <CancelIcon color="error" fontSize="large" />}
        </Stack>
        {isLoading && <LinearProgress />}
        {error && isErrorShown(error) && (
          <Alert severity="error">{'data' in error && (error?.data as string)}</Alert>
        )}
      </Stack>
    </form>
  );
};
